import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthFacade } from '../store/auth.facade';
import { TokenService } from './token.service';

export const authGuard = () => {
  const tokenService = inject(TokenService);
  const authFacade = inject(AuthFacade);
  const router = inject(Router);

  if (tokenService.isTokenValid()) {
    if (!tokenService.getRoles()?.length) {
      router.navigate(['/totp']);
      return false;
    }
    return true;
  }

  authFacade.forceLogout();

  return false;
};
