export {
  faCirclePlus as faAdd,
  faArrowRotateRight,
  faArrowUpFromBracket,
  faArrowUpRightFromSquare,
  faBackward,
  faBackwardFast,
  faBan,
  faBars,
  faBell,
  faBorderNone,
  faBoxArchive,
  faCalendarAlt,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircleArrowDown,
  faCircleArrowUp,
  faCircleCheck,
  faCircleExclamation,
  faCircleInfo,
  faCirclePlus,
  faCircleQuestion,
  faCircleXmark,
  faClone,
  faClose,
  faCloudUpload,
  faCodeMerge,
  faCopy,
  faCreditCard,
  faTrash as faDelete,
  faDownload,
  faEllipsisVertical,
  faCircleExclamation as faError,
  faExclamationTriangle,
  faEye,
  faEyeSlash,
  faFileAlt,
  faFileCircleCheck,
  faFileExcel,
  faFileInvoice,
  faFileInvoiceDollar,
  faFilePdf,
  faFilter,
  faFilterCircleXmark,
  faGear,
  faHourglassHalf,
  faInbox,
  faKey,
  faLock,
  faMinusSquare,
  faMoneyBillAlt,
  faMoneyBillTransfer,
  faPaperPlane,
  faPause,
  faPen,
  faPencil,
  faPencilAlt,
  faPerson,
  faPlay,
  faPlus,
  faQuestion,
  faReplyAll,
  faRotateLeft,
  faRotateRight,
  faSitemap,
  faSquare,
  faStopwatch,
  faStore,
  faSuitcase,
  faSync,
  faTasks,
  faTimesCircle,
  faTrash,
  faTriangleExclamation,
  faUnlock,
  faUpload,
  faUser,
  faUserLock,
  faWallet,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
