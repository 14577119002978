import { DestroyRef, Injectable, inject } from '@angular/core';
import { CustomerHttpService } from '@app/api';
import { VoucherService } from '@app/features/voucher-details/services/voucher.service';
import { ComponentStore } from '@ngrx/component-store';
import { tapResponse } from '@ngrx/operators';
import { Papa, ParseResult } from 'ngx-papaparse';
import { Observable, Subscription, switchMap, tap } from 'rxjs';
import { CsvTemplate } from '../models/csv-template';
import { CsvUploadTemplateState } from '../models/csv-upload-template-state';

import { CSV_PARSE_DEFAULT_CONFIG } from '@givve/csv-upload';
import { NotificationService } from '@givve/ui-kit/services';
import { TranslateService } from '@ngx-translate/core';
import { CsvTemplateService } from '../services/csv-template.service';

@Injectable()
export abstract class CsvUploadTemplateStore<T> extends ComponentStore<CsvUploadTemplateState> {
  readonly vm$ = this.select((state) => state);

  protected csvTemplateService = inject(CsvTemplateService);
  protected voucherService = inject(VoucherService);
  protected customerService = inject(CustomerHttpService);
  protected destroyRef = inject(DestroyRef);
  private notificationService = inject(NotificationService);
  private translateService = inject(TranslateService);
  papa = inject(Papa);

  private _default_state: CsvUploadTemplateState;

  constructor(DEFAULT_STATE: CsvUploadTemplateState) {
    super(DEFAULT_STATE);
    this._default_state = DEFAULT_STATE;

    this.patchState({ templates: this.getTemplates() });
  }

  abstract getPreviewdialogData(): T;

  public readonly preloadDataAndValidatePossibleStructure = this.effect((file$: Observable<File>) =>
    file$.pipe(
      tap(() => this.resetState()),
      tap((file: File) => {
        this.papa.parse(file, {
          ...CSV_PARSE_DEFAULT_CONFIG,
          complete: (parseResult: ParseResult) => {
            //sollten keine Einträge in der CSV Datei sein, dann wird eine Benachrichtigung angezeigt
            if (parseResult.data.length === 0) {
              this.notificationService.open({
                message: this.translateService.instant('csv_upload.no_entries_found_in_csv'),
                config: { duration: 5000 },
              });

              return;
            }

            // Überprüfen ob die Struktur der CSV Datei mit einer der Vorlagen übereinstimmt
            const possibleTemplates = this.getTemplates()
              .map((template) => {
                const valid = this.csvTemplateService.validateCsvStructure(template.form, parseResult.data);
                if (valid) {
                  return template;
                }
              })
              .filter((template): template is CsvTemplate => template !== undefined);

            // sollte keine passende Vorlage gefunden werden, wird eine Benachrichtigung angezeigt
            if (possibleTemplates.length === 0) {
              this.notificationService.open({
                message: this.translateService.instant('csv_upload.no_matching_template_found_with_structure_from_csv'),
                config: { duration: 5000 },
              });

              return;
            }
            this.afterPreload(file, parseResult, possibleTemplates);
          },
          error: (error, file) => {
            console.error(`Error parsing file "${file.name}": `, error);
            return;
          },
        });
      })
    )
  );

  protected abstract afterPreload(file: File, parseResult: ParseResult, possibleTemplates: CsvTemplate[]): void;

  protected readonly preloadCustomer = this.effect((token$: Observable<string>) =>
    token$.pipe(
      tap(() => this.patchState({ statusType: 'UPDATING' })),
      switchMap((token) => {
        return this.voucherService.loadVoucherByToken(token).pipe(
          switchMap((voucher) =>
            this.customerService.getObject({ id: voucher[0]!.customer.id }).pipe(
              tapResponse(
                (customer) => {
                  this.state().csvUploadFormGroup.patchValue({ customer, product: voucher[0]!.category });
                  this.patchState({ statusType: 'DATA' });
                },
                () => {}
              )
            )
          )
        );
      })
    )
  );

  abstract readonly openCsvPreviewDialog: (observableOrValue: T | Observable<T>) => Subscription;

  abstract readonly openInfoCsvPreviewDialog: (
    observableOrValue: CsvTemplate<any> | Observable<CsvTemplate<any>>
  ) => Subscription;

  readonly setupCsvData = this.updater((state, data: { csvData: any[]; csvTemplate: CsvTemplate }) => {
    const csvFormGroup = this.csvTemplateService.createFormGroup(data.csvTemplate, data.csvData);
    return {
      ...state,
      csvFormGroup,
      csvData: data.csvData,
    };
  });

  readonly resetState = this.updater(() => {
    this._default_state.csvUploadFormGroup.reset();
    return { ...this._default_state, templates: this.getTemplates() };
  });

  abstract getTemplates(): CsvTemplate[];
}
