@if (vm$ | async; as vm) {
  <givve-content-toolbar
    [title]="'csv_upload.component.csv_upload_container.load-orders.title' | translate"></givve-content-toolbar>

  <mat-card class="tw-mb-6">
    <mat-card-content class="tw-flex tw-gap-6">
      <div class="tw-w-2/3">
        @if (vm.statusType !== 'UPDATING') {
          <form
            [formGroup]="vm.csvUploadFormGroup"
            autocomplete="off">
            <givve-file-drop-area (fileAdded)="handleFileDropAreaFileAdded($event)">
              <div class="tw-text-[var(--csv-upload-dark-grey, var(--dark-grey))] tw-flex tw-items-center">
                <div class="tw-mr-3">
                  <svg-icon
                    key="upload"
                    fontSize="22px"></svg-icon>
                </div>
                <div class="tw-leading-normal">
                  @if (!vm.csvUploadFormGroup.getRawValue().file) {
                    <div class="tw-text-lg">
                      {{ 'csv_upload.component.csv_upload_content.place_csv_file' | translate }}
                    </div>
                    <div
                      class="tw-text-xs"
                      [innerHTML]="'csv_upload.component.csv_upload_content.or_select_folder' | translate"></div>
                  } @else {
                    <div class="tw-text-lg">{{ vm.csvUploadFormGroup.getRawValue().file.name }}</div>
                  }
                </div>
              </div>
            </givve-file-drop-area>

            @if (vm.csvUploadFormGroup.getRawValue().file) {
              <section class="tw-mt-4 tw-mb-2">
                @if (vm.csvUploadFormGroup.controls.product.value) {
                  <app-content-row>
                    <app-content-row-label class="tw-pl-4">
                      {{ 'common.product' | translate }}
                    </app-content-row-label>
                    <app-content-row-value>
                      <givve-product-badge [product]="vm.csvUploadFormGroup.controls.product.value">{{
                        vm.csvUploadFormGroup.controls.product.value | humanizeVoucherCategory
                      }}</givve-product-badge>
                    </app-content-row-value>
                  </app-content-row>
                }
                @if (vm.csvUploadFormGroup.controls.customer.value) {
                  <app-content-row>
                    <app-content-row-label class="tw-pl-4">
                      {{ 'common.customer' | translate }}
                    </app-content-row-label>
                    <app-content-row-value class="tw-flex-col tw-items-start">
                      <span data-cy="csv-upload-for-load-orders-customer">
                        {{ vm.csvUploadFormGroup.controls.customer.value.name }}</span
                      >

                      @if (vm.csvUploadFormGroup.controls.customer.errors?.invalid_customer) {
                        <mat-error>
                          {{ 'csv-upload.customer_needs_to_be_active' | translate }}
                        </mat-error>
                      }
                    </app-content-row-value>
                  </app-content-row>
                }
              </section>

              <mat-form-field>
                <mat-label>{{ 'common.due_date' | translate }}</mat-label>
                <input
                  [matDatepicker]="picker"
                  matInput
                  formControlName="due_date"
                  placeholder="DD/MM/YYYY"
                  data-cy="csv-upload-for-load-orders-due-date-text-input" />
                <mat-datepicker-toggle
                  [for]="picker"
                  matIconSuffix
                  data-cy="csv-upload-for-load-orders-due-date-datepicker" />
                <mat-datepicker #picker></mat-datepicker>
                @if (vm.csvUploadFormGroup.controls.due_date.hasError('matDatepickerParse')) {
                  <mat-error>
                    {{ 'common.errors.invalid_date' | translate }}
                  </mat-error>
                }
              </mat-form-field>

              <button
                class="tw-float-right"
                [disabled]="!vm.csvUploadFormGroup.valid"
                (click)="openCSVPreviewDialog()"
                color="primary"
                data-cy="csv-upload-for-load-orders-next-button"
                mat-flat-button>
                {{ 'common.next' | translate }}
              </button>
            }
          </form>
        } @else {
          <givve-content-loading loading />
        }

        @if (vm.statusType === 'ERROR') {
          <h3 class="tw-mb-0 tw-pt-4 tw-text-[var(--warn)]">{{ 'common.errors' | translate }}</h3>
          <mat-list>
            @for (item of vm.error?.['error']?.['errors']; track $index; let index = $index; let last = $last) {
              <mat-list-item class="tw-cursor-pointer hover:tw-bg-[#F1F1F1]">
                <div matListItemTitle>
                  <b>{{ item.source.pointer }}</b
                  >: {{ item.detail }}
                </div>
              </mat-list-item>
              @if (!last) {
                <mat-divider></mat-divider>
              }
            }
          </mat-list>
        }
      </div>

      <div class="tw-w-1/3">
        <h3 class="tw-my-2">{{ 'common.templates' | translate }}</h3>
        <div matListItemLine>{{ 'csv-upload.templates.info' | translate }}</div>
        <mat-list class="tw-pl-3">
          @for (item of vm.templates; track $index; let i = $index; let last = $last) {
            @if ((i === 0 || vm.templates[i - 1].type !== item.type) && vm.templates.length > 1) {
              <span matListItemTitle>{{ 'common.' + item.type | translate }}</span>
              @if (item.description) {
                <div matListItemLine>{{ item.description | translate }}</div>
              }
            }

            <mat-list-item
              class="tw-cursor-pointer tw-pl-6 hover:tw-bg-[#F1F1F1]"
              (click)="downloadTemplate(item)">
              <div matListItemTitle>
                {{ item.label ? (item.label | translate) : ('common.' + item.category | translate) }}
              </div>
              <button
                (click)="openInfoForTemplateDialog(item, $event)"
                mat-icon-button
                matListItemMeta>
                <fa-icon icon="circle-info" />
              </button>
            </mat-list-item>
            @if (i + 1 < vm.templates.length && vm.templates[i + 1].type === item.type) {
              <mat-divider></mat-divider>
            }
          }
        </mat-list>
      </div>
    </mat-card-content>
  </mat-card>
}
