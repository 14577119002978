import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@app/env';
import { Observable } from 'rxjs';
import { TokenService } from './token.service';

@Injectable()
export class TokenInterceptorService implements HttpInterceptor {
  private tokenService = inject(TokenService);

  private excludedDomains = ['amazonaws.com'];

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.tokenService.getAccessToken();
    const shouldSkip = this.excludedDomains.some((domain) => request.url.includes(domain));

    if (token && !shouldSkip) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
          AcceptVersion: 'v2',
          'referer-app': 'new-admin;v2',
          'Api-Access-Id': environment.apiAccessId,
        },
      });
    }
    return next.handle(request);
  }
}
