<h2 mat-dialog-title>{{ 'common.releasenotes' | translate }}</h2>
<mat-dialog-content class="tw-flex tw-flex-col tw-gap-5">
  @for (note of data; track $index) {
    <app-releasenote-card [note]="note"></app-releasenote-card>
  }
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button
    color="primary"
    mat-flat-button
    mat-dialog-close>
    {{ 'common.ok' | translate }}
  </button>
</mat-dialog-actions>
