import { inject, Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Customer, ProductCategoryType } from '@givve/ui-kit/models';
import { MomentInput } from 'moment';
import { ParseResult } from 'ngx-papaparse';
import { mergeMap, Observable, switchMap, tap } from 'rxjs';
import { CSV_TEMPLATE_TYPE, CsvTemplate } from '../../models/csv-template';
import { CsvUploadTemplateState } from '../../models/csv-upload-template-state';
import { PreviewDialogData } from '../../models/preview-dialog-data';
import { CsvUploadTemplateStore } from '../../store/csv-upload-template.store';
import { onlyActiveCustomer } from '../../utils/co-template-validators';
import { LoCsvTemplateBuilder } from '../../utils/lo-csv-template-builder';
import { LoCsvUploadService } from '../services/lo-csv-upload.service';

export const DEFAULT_STATE: CsvUploadTemplateState = {
  statusType: 'INIT',
  templates: [],
  csvFormGroup: null,
  csvData: [],
  csvUploadFormGroup: new FormGroup({
    template: new FormControl<CsvTemplate | null>(null, [Validators.required]),
    customer: new FormControl<Customer | null>(null, [Validators.required, onlyActiveCustomer()]),
    file: new FormControl<File | null>(null, [Validators.required]),
    due_date: new FormControl<MomentInput | null>(null),
    product: new FormControl<ProductCategoryType | null>({ value: null, disabled: true }, [Validators.required]),
  }),
  validationData: {
    possibleImageIds: [],
    possibleCustomers: [],
    possibleTemplates: [],
    tokens: [],
    emails: [],
  },
  error: null,
};

@Injectable()
export class LoCsvUploadTemplateStore extends CsvUploadTemplateStore<{
  csvData: any[];
  customerId: string;
  csvTemplate: CsvTemplate;
  due_date: MomentInput;
  product: ProductCategoryType;
}> {
  private csvUploadService = inject(LoCsvUploadService);

  constructor() {
    super(DEFAULT_STATE);
  }

  getPreviewdialogData(): {
    csvData: any[];
    customerId: string;
    csvTemplate: CsvTemplate;
    due_date: MomentInput;
    product: ProductCategoryType;
  } {
    return {
      csvData: this.state().csvData,
      customerId: this.state().csvUploadFormGroup.getRawValue().customer.id,
      csvTemplate: this.state().csvUploadFormGroup.getRawValue().template,
      due_date: this.state().csvUploadFormGroup.getRawValue().due_date,
      product: this.state().csvUploadFormGroup.getRawValue().product,
    };
  }

  protected afterPreload(file: File, parseResult: ParseResult, possibleTemplates: CsvTemplate[]): void {
    this.patchState((state) => {
      state.csvUploadFormGroup.patchValue({
        file,
        template: possibleTemplates.length === 1 ? possibleTemplates[0] : null,
      });
      return {
        ...state,
        csvData: parseResult.data,
        validationData: {
          ...state.validationData,
          tokens: parseResult.data.filter((row: any) => row.Token).map((row: any) => row.Token),
          possibleTemplates,
        },
      };
    });

    // wir nehmen, wenn vorhanden, den ersten Token aus der Liste um im nächsten Schritt nach dem Customer zu suchen
    if (this.state().validationData.tokens.length) {
      this.preloadCustomer(this.state().validationData.tokens[0]!);
    }
  }

  openCsvPreviewDialog = this.effect(
    (
      data$: Observable<{
        csvData: any[];
        customerId: string;
        csvTemplate: CsvTemplate;
        due_date: MomentInput;
        product: ProductCategoryType;
      }>
    ) => {
      return data$.pipe(
        tap(() => this.patchState({ statusType: 'UPDATING' })),
        switchMap((data) => {
          var csvTemplate = LoCsvTemplateBuilder.fromTemplate(data.csvTemplate)
            .setValidationData(this.state().validationData)
            .build();

          this.setupCsvData({ csvData: data.csvData, csvTemplate });

          var previewData: PreviewDialogData = {
            template: csvTemplate,
            example: false,
            csvFormGroup: this.state().csvFormGroup,
          };
          return this.csvUploadService
            .openDialog(previewData, data.customerId, data.due_date, data.product)
            .pipe(tap(() => this.patchState({ statusType: 'INIT' })));
        })
      );
    }
  );

  openInfoCsvPreviewDialog = this.effect((csvTemplate$: Observable<CsvTemplate>) => {
    return csvTemplate$.pipe(
      mergeMap((csvTemplate) => {
        const validationData = {
          ...this.state().validationData,
          tokens: csvTemplate.exampleData.map((row: any) => row.Token),
        };

        csvTemplate = LoCsvTemplateBuilder.fromTemplate(csvTemplate).setValidationData(validationData).build();

        this.patchState((state) => ({
          ...state,
          validationData,
        }));
        this.setupCsvData({ csvData: csvTemplate.exampleData ?? [{}], csvTemplate });

        var previewData: PreviewDialogData = {
          template: csvTemplate,
          example: true,
          csvFormGroup: this.state().csvFormGroup,
        };
        return this.csvUploadService.openDialog(previewData);
      })
    );
  });

  getTemplates(): CsvTemplate[] {
    return [
      new LoCsvTemplateBuilder(CSV_TEMPLATE_TYPE.LOAD_ORDER)
        .setLabel('csv_upload.label.load_order')
        .setValidationData(this.state().validationData)
        .setExampleData(exampleData)
        .build(),
    ];
  }
}

const exampleData = [
  {
    Token: '',
    Betrag: '',
  },
  {
    Token: '767626896',
    Betrag: 'Betrag: 1000€',
  },
  {
    Token: '767626895',
    Betrag: '10,00',
  },
];
