import { Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CardDesign, CardDesignsHttpService, CustomerCardDesignsHttpService } from '@app/api';
import { mustBeObjectValidator } from '@app/features/clients/sepa-mandate-tab/validator';
import { Customer } from '@givve/ui-kit/models';
import { tapResponse } from '@ngrx/operators';
import { ParseResult } from 'ngx-papaparse';
import { Observable, map, mergeMap, switchMap, tap } from 'rxjs';
import {
  CSV_TEMPLATE_TYPE,
  CsvTemplate,
  CsvTemplateFormControl,
  CsvTemplateFormGroup,
} from '../../models/csv-template';
import { CsvUploadTemplateState } from '../../models/csv-upload-template-state';
import { PreviewDialogData } from '../../models/preview-dialog-data';
import { CsvUploadTemplateStore } from '../../store/csv-upload-template.store';
import { CoCsvTemplateBuilder } from '../../utils/co-csv-template-builder';
import { onlyActiveCustomer } from '../../utils/co-template-validators';
import { CoCsvUploadService } from '../services/co-csv-upload.service';

export const DEFAULT_STATE: CsvUploadTemplateState = {
  statusType: 'INIT',
  templates: [],
  csvFormGroup: null,
  csvData: [],
  csvUploadFormGroup: new FormGroup({
    template: new FormControl<CsvTemplate | null>(null, [Validators.required]),
    customer: new FormControl<Customer | null>(null, [
      Validators.required,
      onlyActiveCustomer(),
      mustBeObjectValidator(),
    ]),
    file: new FormControl<File | null>(null, [Validators.required]),
  }),
  validationData: {
    possibleImageIds: [],
    possibleCustomers: [],
    possibleTemplates: [],
    tokens: [],
    emails: [],
  },
  error: null,
};

@Injectable()
export class CoCsvUploadTemplateStore extends CsvUploadTemplateStore<{
  csvData: any[];
  customerId: string;
  csvTemplate: CsvTemplate;
}> {
  readonly vm$ = this.select((state) => state);

  private csvUploadService = inject(CoCsvUploadService);
  private cardDesignsHttpService = inject(CardDesignsHttpService);
  private customerCardDesignService = inject(CustomerCardDesignsHttpService);

  constructor() {
    super(DEFAULT_STATE);
    // sobald das Formular vollständig ausgefüllt ist, wird der CSV Preview Dialog geöffnet.
    this.state()
      .csvUploadFormGroup.statusChanges.pipe(
        takeUntilDestroyed(this.destroyRef),
        tap((status) => {
          if (status === 'VALID') {
            this.openCsvPreviewDialog(this.getPreviewdialogData());
          }
        })
      )
      .subscribe();
  }

  getPreviewdialogData(): { csvData: any[]; customerId: string; csvTemplate: CsvTemplate } {
    return {
      csvData: this.state().csvData,
      customerId: this.state().csvUploadFormGroup.getRawValue().customer.id,
      csvTemplate: this.state().csvUploadFormGroup.getRawValue().template,
    };
  }

  protected afterPreload(file: File, parseResult: ParseResult, possibleTemplates: CsvTemplate[]): void {
    // sollten mehrere oder eine passende Vorlage gefunden werden, wird die CSV Datei genommen
    this.patchState((state) => {
      state.csvUploadFormGroup.patchValue({
        file,
        template: possibleTemplates.length === 1 ? possibleTemplates[0] : null,
      });
      return {
        ...state,
        csvData: parseResult.data,
        validationData: {
          ...state.validationData,
          tokens: parseResult.data.filter((row: any) => row.Token).map((row: any) => row.Token),
          emails: parseResult.data.filter((row: any) => row.Email).map((row: any) => row.Email),
          possibleTemplates,
        },
      };
    });

    // wir nehmen, wenn vorhanden, den ersten Token aus der Liste um im nächsten Schritt nach dem Customer zu suchen
    if (this.state().validationData.tokens.length) {
      this.preloadCustomer(this.state().validationData.tokens[0]!);
    }

    // sollten wir eine Image Id in der CSV Datei finden, dann werden alle möglichen Kunden dazu geladen, die dieses Design haben
    if (parseResult.data[0]['Image Id']) {
      this.preloadCustomersFromImageId(parseResult.data[0]['Image Id']);
    }
  }

  private readonly preloadCustomersFromImageId = this.effect((imageId$: Observable<string>) =>
    imageId$.pipe(
      tap(() => this.patchState({ statusType: 'UPDATING' })),
      switchMap((imageId) => {
        return this.cardDesignsHttpService.searchByType({ search: imageId, sort: '-image_id' }).pipe(
          map((response) => response.data[0]),
          tapResponse(
            (detail) => {
              this.loadCustomers(detail!);
            },
            () => {
              this.patchState({ statusType: 'DATA' });
            }
          )
        );
      })
    )
  );

  readonly loadCustomers = this.effect((cardDesign$: Observable<CardDesign>) =>
    cardDesign$.pipe(
      tap(() => this.patchState({ statusType: 'UPDATING' })),
      switchMap((cardDesign) => {
        // take the first customersPageSize customer ids
        const idParamValue = cardDesign?.customers
          .slice(0, 200)
          .map((c) => c.id)
          .join(',');
        const options = {
          filter: {
            ['id']: {
              $in: idParamValue,
            },
          },
        };

        return this.customerService.getObjects({}, options).pipe(
          tapResponse(
            (response) => {
              this.patchState((state) => {
                if (response.data.length === 1) {
                  this.state().csvUploadFormGroup.patchValue({ customer: response.data[0] });
                }
                return {
                  ...state,
                  statusType: 'DATA',
                  validationData: { ...state.validationData, possibleCustomers: response.data },
                };
              });
            },
            () => {
              this.patchState({ statusType: 'DATA' });
            }
          )
        );
      })
    )
  );

  openCsvPreviewDialog = this.effect(
    (
      data$: Observable<{
        csvData: any[];
        customerId: string;
        csvTemplate: CsvTemplate;
      }>
    ) => {
      return data$.pipe(
        tap(() => this.patchState({ statusType: 'UPDATING' })),
        mergeMap((data) => {
          return this.customerCardDesignService.getObjects({ client_id: data.customerId }).pipe(
            tap(({ data }) => {
              this.patchState((state) => ({
                ...state,
                validationData: {
                  ...state.validationData,
                  possibleImageIds: data.map((cardDesign) => cardDesign.image_id),
                },
              }));
            }),
            mergeMap(() => {
              var csvTemplate = CoCsvTemplateBuilder.fromTemplate(data.csvTemplate)
                .setValidationData(this.state().validationData)
                .build();
              this.setupCsvData({ csvData: data.csvData, csvTemplate });

              var previewData: PreviewDialogData = {
                template: csvTemplate,
                example: false,
                csvFormGroup: this.state().csvFormGroup,
              };
              return this.csvUploadService
                .openDialog(previewData, data.customerId, data.csvTemplate.category)
                .pipe(tap(() => this.patchState({ statusType: 'INIT' })));
            })
          );
        })
      );
    }
  );

  openInfoCsvPreviewDialog = this.effect((csvTemplate$: Observable<CsvTemplate>) => {
    return csvTemplate$.pipe(
      mergeMap((csvTemplate) => {
        const validationData = {
          ...this.state().validationData,
          tokens: csvTemplate.exampleData.map((row: any) => row.Token),
          emails: csvTemplate.exampleData.map((row: any) => row.Email),
          possibleImageIds: ['BONAYOU0000031'],
        };

        csvTemplate = CoCsvTemplateBuilder.fromTemplate(csvTemplate).setValidationData(validationData).build();

        this.patchState((state) => ({
          ...state,
          validationData,
        }));
        this.setupCsvData({ csvData: csvTemplate.exampleData ?? [{}], csvTemplate });

        var previewData: PreviewDialogData = {
          template: csvTemplate,
          example: true,
          csvFormGroup: this.state().csvFormGroup,
        };
        return this.csvUploadService.openDialog(previewData);
      })
    );
  });

  getTemplates(): CsvTemplate[] {
    return [
      new CoCsvTemplateBuilder(CSV_TEMPLATE_TYPE.NEW_CARD, 'benefit_in_kind')
        .setValidationData(this.state().validationData)
        .setLabel('csv_upload.label.benefit_in_kind_regional')
        .setExampleData(exampleDataNewCardBenefitInKindAndLunch)
        .setAdditionalFields({
          constraints: new CsvTemplateFormGroup(
            {
              regional: new FormGroup({
                zip_code: new CsvTemplateFormControl({
                  label: 'Regionale Einschränkung PLZ',
                }),
              }),
            },
            { removeWhenEmpty: true }
          ),
        })
        .build(),

      new CoCsvTemplateBuilder(CSV_TEMPLATE_TYPE.NEW_CARD, 'benefit_in_kind')
        .setValidationData(this.state().validationData)
        .setLabel('csv_upload.label.benefit_in_kind_national_merchant')
        .setExampleData(exampleDataNewCardNationalBenefitInKInd)
        .setAdditionalFields({
          constraints: new CsvTemplateFormGroup(
            {
              benefit_in_kind: new FormGroup({
                merchant_id: new CsvTemplateFormControl({
                  label: 'Einschränkung Merchant Id',
                }),
              }),
            },
            { removeWhenEmpty: true }
          ),
        })
        .build(),

      new CoCsvTemplateBuilder(CSV_TEMPLATE_TYPE.NEW_CARD, 'benefit_in_kind')
        .setValidationData(this.state().validationData)
        .setLabel('csv_upload.label.benefit_in_kind_behoerdenkarte')
        .setExampleData(exampleDataNewCardUnlimitedBehoerdenAndBezahlkarte)
        .setAdditionalFields({
          constraints: new CsvTemplateFormGroup({
            benefit_in_kind: new CsvTemplateFormControl({ value: {} }),
          }),
        })
        .build(),

      new CoCsvTemplateBuilder(CSV_TEMPLATE_TYPE.NEW_CARD, 'lunch')
        .setValidationData(this.state().validationData)
        .setExampleData(exampleDataNewCardBenefitInKindAndLunch)
        .setAdditionalFields({
          constraints: new CsvTemplateFormGroup(
            {
              lunch: new FormGroup({
                zip_code: new CsvTemplateFormControl({
                  label: 'Regionale Einschränkung PLZ',
                }),
              }),
            },
            { removeWhenEmpty: true }
          ),
          reference_voucher_token: new CsvTemplateFormControl({
            label: 'Bezugskarte',
            removeWhenEmpty: true,
          }),
          transfer_balance: new CsvTemplateFormControl({ value: 'false' }),
          is_replacement: new CsvTemplateFormControl({ value: 'false' }),
          inherit_constraints: new CsvTemplateFormControl({ value: 'false' }),
        })
        .build(),

      new CoCsvTemplateBuilder(CSV_TEMPLATE_TYPE.NEW_CARD, 'unlimited')
        .setValidationData(this.state().validationData)
        .setExampleData(exampleDataNewCardUnlimitedBehoerdenAndBezahlkarte)
        .setAdditionalFields({
          constraints: new FormGroup({
            unlimited: new CsvTemplateFormControl({ value: {} }),
          }),
        })
        .build(),

      new CoCsvTemplateBuilder(CSV_TEMPLATE_TYPE.NEW_CARD, 'public_sector')
        .setValidationData(this.state().validationData)
        .setExampleData(exampleDataNewCardUnlimitedBehoerdenAndBezahlkarte)
        .setAdditionalFields({
          constraints: new FormGroup({
            public_sector: new CsvTemplateFormControl({ value: {} }),
          }),
        })
        .build(),

      new CoCsvTemplateBuilder(CSV_TEMPLATE_TYPE.RECARDING, 'benefit_in_kind')
        .setValidationData(this.state().validationData)
        .setExampleData(exampleDataRecardingAll)
        .build(),

      new CoCsvTemplateBuilder(CSV_TEMPLATE_TYPE.RECARDING, 'lunch')
        .setValidationData(this.state().validationData)
        .setExampleData(exampleDataRecardingAll)
        .build(),

      new CoCsvTemplateBuilder(CSV_TEMPLATE_TYPE.RECARDING, 'unlimited')
        .setValidationData(this.state().validationData)
        .setExampleData(exampleDataRecardingAll)
        .build(),

      new CoCsvTemplateBuilder(CSV_TEMPLATE_TYPE.RECARDING, 'public_sector')
        .setValidationData(this.state().validationData)
        .setExampleData(exampleDataRecardingAll)
        .build(),

      new CoCsvTemplateBuilder(CSV_TEMPLATE_TYPE.BULK_RECARDING, 'benefit_in_kind')
        .setValidationData(this.state().validationData)
        .setExampleData(exampleDataBulkRecarding)
        .setDescription('csv_upload.type.bulk_recarding.benefit_in_kind')
        .build(),

      new CoCsvTemplateBuilder(CSV_TEMPLATE_TYPE.BULK_RECARDING, 'lunch')
        .setValidationData(this.state().validationData)
        .setExampleData(exampleDataBulkRecarding)
        .build(),

      new CoCsvTemplateBuilder(CSV_TEMPLATE_TYPE.BULK_RECARDING, 'unlimited')
        .setValidationData(this.state().validationData)
        .setExampleData(exampleDataBulkRecarding)
        .build(),
    ];
  }
}

const exampleDataNewCardNationalBenefitInKInd = [
  {
    'Prägezeile 1': '',
    'Prägezeile 2': '',
    'Image Id': '',
    Vorname: '',
    Nachname: '',
    Personalnummer: '',
    Email: '',
    'Einschränkung Merchant Id': '619514722af35c13b7376353',
  },
  {
    'Prägezeile 1': 'Maximilian Schmidt',
    'Prägezeile 2': 'Schmidt GmbH',
    'Image Id': 'BONAYOU0000031',
    Vorname: 'Maximilian',
    Nachname: 'Schmidt',
    Personalnummer: '123456',
    Email: 'max.schmidt@gmx.de',
    'Einschränkung Merchant Id': '619514722af35c13b7376353',
  },
  {
    'Prägezeile 1': 'Maximilian Peter Franz Mustermann Müller Schmidt',
    'Prägezeile 2': 'Mustermann Müller Schmidt GmbH',
    'Image Id': '',
    Vorname: 'Maximilian Peter Franz',
    Nachname: 'Mustermann Müller Schmidt',
    Personalnummer: '',
    Email: 'test1',
    'Einschränkung Merchant Id': '619514722af35c13b7376353',
  },
  {
    'Prägezeile 1': 'Test2',
    'Prägezeile 2': '',
    'Image Id': 'BONAYOU0000031',
    Vorname: 'Richard',
    Nachname: 'Müller',
    Personalnummer: '',
    Email: '',
    'Einschränkung Merchant Id': '619514722af35c13b7376353',
  },
];

const exampleDataNewCardBenefitInKindAndLunch = [
  {
    'Prägezeile 1': '',
    'Prägezeile 2': '',
    'Image Id': '',
    Vorname: '',
    Nachname: '',
    Personalnummer: '',
    Email: '',
    'Regionale Einschränkung PLZ': '',
  },
  {
    'Prägezeile 1': 'Maximilian Schmidt',
    'Prägezeile 2': 'Schmidt GmbH',
    'Image Id': 'BONAYOU0000031',
    Vorname: 'Maximilian',
    Nachname: 'Schmidt',
    Personalnummer: '123456',
    Email: 'max.schmidt@gmx.de',
    'Regionale Einschränkung PLZ': '51688',
  },
  {
    'Prägezeile 1': 'Maximilian Peter Franz Mustermann Müller Schmidt',
    'Prägezeile 2': 'Mustermann Müller Schmidt GmbH',
    'Image Id': '',
    Vorname: 'Maximilian Peter Franz',
    Nachname: 'Mustermann Müller Schmidt',
    Personalnummer: '',
    Email: 'test1',
    'Regionale Einschränkung PLZ': '51688',
  },
  {
    'Prägezeile 1': 'Test2',
    'Prägezeile 2': '',
    'Image Id': 'BONAYOU0000031',
    Vorname: 'Richard',
    Nachname: 'Müller',
    Personalnummer: '',
    Email: '',
    'Regionale Einschränkung PLZ': '51687',
  },
];

const exampleDataNewCardUnlimitedBehoerdenAndBezahlkarte = [
  {
    'Prägezeile 1': '',
    'Prägezeile 2': '',
    'Image Id': '',
    Vorname: '',
    Nachname: '',
    Personalnummer: '',
    Email: '',
  },
  {
    'Prägezeile 1': 'Maximilian Schmidt',
    'Prägezeile 2': 'Schmidt GmbH',
    'Image Id': 'BONAYOU0000031',
    Vorname: 'Maximilian',
    Nachname: 'Schmidt',
    Personalnummer: '123456',
    Email: 'max.schmidt@gmx.de',
  },
  {
    'Prägezeile 1': 'Maximilian Peter Franz Mustermann Müller Schmidt',
    'Prägezeile 2': 'Mustermann Müller Schmidt GmbH',
    'Image Id': '',
    Vorname: 'Maximilian Peter Franz',
    Nachname: 'Mustermann Müller Schmidt',
    Personalnummer: '',
    Email: 'test1',
  },
  {
    'Prägezeile 1': 'Test2',
    'Prägezeile 2': '',
    'Image Id': 'BONAYOU0000031',
    Vorname: 'Richard',
    Nachname: 'Müller',
    Personalnummer: '',
    Email: '',
  },
];

const exampleDataRecardingAll = [
  {
    Token: '',
    'Prägezeile 1': '',
    'Prägezeile 2': '',
    'Image Id': '',
    Vorname: '',
    Nachname: '',
    Personalnummer: '',
    Email: '',
  },
  {
    Token: '380041274',
    'Prägezeile 1': 'Maximilian Schmidt',
    'Prägezeile 2': 'Schmidt GmbH',
    'Image Id': 'BONAYOU0000031',
    Vorname: 'Maximilian',
    Nachname: 'Schmidt',
    Personalnummer: '123456',
    Email: 'max.schmidt@gmx.de',
  },
  {
    Token: '380041272',
    'Prägezeile 1': 'Maximilian Peter Franz Mustermann Müller Schmidt',
    'Prägezeile 2': 'Mustermann Müller Schmidt GmbH',
    'Image Id': 'BONAYOU0000031',
    Vorname: 'Maximilian Peter Franz',
    Nachname: 'Mustermann Müller Schmidt',
    Personalnummer: '',
    Email: 'test',
  },
  {
    Token: '380041272',
    'Prägezeile 1': 'Test',
    'Prägezeile 2': 'Test',
    'Image Id': 'BONAYOU0000031',
    Vorname: 'Richard',
    Nachname: 'Müller',
    Personalnummer: '',
    Email: 'test',
  },
];

const exampleDataBulkRecarding = [
  {
    KundenNr: '',
    KundenName: '',
    VoucherID: '',
    Token: '',
    Konfiguration: '',
    Prägezeile1: '',
    Prägezeile2: '',
    Vorname: '',
    Nachname: '',
    Personalnummer: '',
    Ablaufdatum: '',
    'Erneuern?': 'Ja',
    'Namensänderung?': '',
  },
  {
    KundenNr: '1113874',
    KundenName: 'PL Gutscheinsysteme GmbH',
    VoucherID: '618ba40a2af35c2472971fa8',
    Token: '860036508',
    Konfiguration: '',
    Prägezeile1: '',
    Prägezeile2: '',
    Vorname: '',
    Nachname: '',
    Personalnummer: '',
    Ablaufdatum: '31.01.2025',
    'Erneuern?': 'Ja',
    'Namensänderung?': '',
  },
  {
    KundenNr: '1113875',
    KundenName: 'PL Gutscheinsysteme GmbH',
    VoucherID: '618ba40a2af35c2472971fa8',
    Token: '860036507',
    Konfiguration: 'Sachbezug',
    Prägezeile1: 'Maximilian Schmidt',
    Prägezeile2: 'Mustermann Schmidt GmbH',
    Vorname: 'Maximilian',
    Nachname: 'Schmidt',
    Personalnummer: '',
    Ablaufdatum: '31.01.2025',
    'Erneuern?': 'Ja',
    'Namensänderung?': '',
  },
  {
    KundenNr: '1113876',
    KundenName: 'PL Gutscheinsysteme GmbH',
    VoucherID: '618ba40a2af35c2472971fa8',
    Token: '860036506',
    Konfiguration: 'Sachbezug',
    Prägezeile1: 'Maximilian Peter Franz Mustermann Müller Schmidt',
    Prägezeile2: 'Mustermann Müller Schmidt GmbH',
    Vorname: 'Maximilian Peter Franz',
    Nachname: 'Mustermann Müller Schmidt',
    Personalnummer: '',
    Ablaufdatum: '31.01.2025',
    'Erneuern?': 'Ja',
    'Namensänderung?': '',
  },
  {
    KundenNr: '1113874',
    KundenName: 'PL Gutscheinsysteme GmbH',
    VoucherID: '618ba40a2af35c2472971fa8',
    Token: '860036506',
    Konfiguration: 'Sachbezug',
    Prägezeile1: 'Maximilian Musterman',
    Prägezeile2: 'Mustermann GmbH',
    Vorname: 'Maximilian ',
    Nachname: 'Mustermann',
    Personalnummer: '',
    Ablaufdatum: '31.01.2025',
    'Erneuern?': 'Ja',
    'Namensänderung?': '',
  },
];
