import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { ContentRowLabelComponent } from '@app/shared/components/content-row/content-row-label/content-row-label.component';
import { ContentRowValueComponent } from '@app/shared/components/content-row/content-row-value/content-row-value.component';
import { ContentRowComponent } from '@app/shared/components/content-row/content-row.component';
import { HumanizeVoucherCategoryPipe } from '@app/shared/pipes/humanize-voucher-category.pipe';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { FileDropAreaComponent } from '@givve/csv-upload';
import { ContentLoadingComponent, ContentToolbarComponent, ProductBadgeComponent } from '@givve/ui-kit/components';
import { SvgIconComponent } from '@ngneat/svg-icon';
import { provideComponentStore } from '@ngrx/component-store';
import { TranslateModule } from '@ngx-translate/core';
import { CsvTemplate } from '../models/csv-template';
import { CsvTemplateService } from '../services/csv-template.service';
import { LoCsvUploadTemplateStore } from './store/lo-csv-upload-template.store';

@Component({
  selector: 'app-csv-upload-for-load-orders',
  imports: [
    CommonModule,
    ContentToolbarComponent,
    MatCardModule,
    FileDropAreaComponent,
    SvgIconComponent,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatSelectModule,
    MatListModule,
    ContentLoadingComponent,
    FaIconComponent,
    MatDatepickerModule,
    ContentRowComponent,
    ContentRowLabelComponent,
    ContentRowValueComponent,
    HumanizeVoucherCategoryPipe,
    ProductBadgeComponent,
  ],
  providers: [provideComponentStore(LoCsvUploadTemplateStore)],
  templateUrl: './csv-upload-for-load-orders.component.html',
})
export class CsvUploadForLoadOrdersComponent implements OnInit {
  private csvTemplateService = inject(CsvTemplateService);

  private loCsvUploadTemplateStore = inject(LoCsvUploadTemplateStore);

  vm$ = this.loCsvUploadTemplateStore.vm$;

  ngOnInit(): void {
    this.loCsvUploadTemplateStore.resetState();
  }

  handleFileDropAreaFileAdded(file: File) {
    this.loCsvUploadTemplateStore.preloadDataAndValidatePossibleStructure(file);
  }

  downloadTemplate(csvTemplate: CsvTemplate) {
    this.csvTemplateService.createCsvTemplate(csvTemplate, 'LO');
  }

  openInfoForTemplateDialog(csvTemplate: CsvTemplate, event: Event) {
    event.stopPropagation();
    this.loCsvUploadTemplateStore.openInfoCsvPreviewDialog(csvTemplate);
  }

  openCSVPreviewDialog() {
    this.loCsvUploadTemplateStore.openCsvPreviewDialog(this.loCsvUploadTemplateStore.getPreviewdialogData());
  }
}
