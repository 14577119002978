<givve-content-toolbar [title]="'common.client-users-managment' | translate"> </givve-content-toolbar>

<mat-card class="tw-mb-6">
  <mat-card-content>
    <div class="tw-flex tw-gap-6">
      <mat-form-field
        appearance="outline"
        subscriptSizing="dynamic">
        <input
          #input
          [placeholder]="'common.email' | translate"
          (keyup.enter)="onSearch(input.value)"
          matInput
          type="email" />
      </mat-form-field>
      <button
        class="tw-h-14 tw-w-52"
        [disabled]="!input.value"
        (click)="onSearch(input.value)"
        color="primary"
        mat-raised-button>
        {{ 'common.search' | translate | uppercase }}
      </button>
    </div>
  </mat-card-content>
</mat-card>

@if (vm$ | async; as vm) {
  @if (vm.statusType === 'DATA') {
    <app-public-user-info
      [publicUser]="vm.user"
      (delete)="onDelete(vm.user.id, $event.id, $event.clientType)"
      (passwordReset)="onPasswordReset(vm.user.id)"
      (resendInvitation)="onResendInvitation(vm.user.id)"></app-public-user-info>
  } @else if (vm.statusType === 'NO_DATA') {
    <givve-no-data>{{ 'common.no_data' | translate }}</givve-no-data>
  }
}
