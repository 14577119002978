import { Routes } from '@angular/router';

import AuthRoutes from '@app/auth/auth.routes';
import { authGuard } from '@app/auth/services/auth.guard';
import { DisableLoadingComponent, FrameComponent, NotFoundComponent } from '@app/core';
import { PushNotificationComponent } from '@app/features/testing/push-notification/push-notification.component';
import { CsvUploadForCardOrdersComponent } from './features/csv-upload-new/csv-upload-for-card-orders/csv-upload-for-card-orders.component';
import { CsvUploadForLoadOrdersComponent } from './features/csv-upload-new/csv-upload-for-load-orders/csv-upload-for-load-orders.component';
import { GlobalSearchStore } from './features/global-search/stores/global-search.store';
import { PublicUsersComponent } from './features/public-users/public-users.component';
import { LunchTaxRulesComponent } from './features/testing/lunch-tax-rules/lunch-tax-rules.component';

export default [
  ...AuthRoutes,
  {
    path: '',
    component: FrameComponent,
    canActivate: [authGuard],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'search',
      },
      {
        path: 'search',
        loadChildren: () => import('@app/features/global-search/global-search.routes'),
        title: 'common.search',
        providers: [GlobalSearchStore],
      },
      {
        path: 'csv-upload',
        loadChildren: () => import('@app/features/csv-upload/csv-upload.routes'),
        title: 'common.csv_upload',
      },
      { path: 'csv-upload/card-order', component: CsvUploadForCardOrdersComponent },
      { path: 'csv-upload/load-order', component: CsvUploadForLoadOrdersComponent },
      {
        path: 'voucher-owners',
        loadChildren: () => import('@app/features/voucher-owners-details/voucher-owners.routes'),
        title: 'common.card_holder',
      },
      {
        path: 'invoices',
        loadChildren: () => import('@app/features/invoices-details/invoice.routes'),
        title: 'common.invoice',
      },
      {
        path: 'card-orders',
        loadChildren: () => import('@app/features/card-orders-details/card-orders.routes'),
        title: 'common.card_orders',
      },
      {
        path: 'load-orders',
        loadChildren: () => import('@app/features/load-orders-details/load-orders.routes'),
        title: 'common.load_orders',
      },
      {
        path: 'scheduled-load-orders',
        loadChildren: () => import('@app/features/scheduled-load-orders/scheduled-load-orders.routes'),
        title: 'common.scheduled_load_orders',
      },
      {
        path: 'unload-orders',
        loadChildren: () => import('@app/features/unload-orders-details/unload-orders.routes'),
        title: 'common.unload_orders',
      },
      {
        path: 'api-accesses',
        loadChildren: () => import('@app/features/api-accesses/api-accesses.routes'),
        title: 'common.api_accesses',
      },
      {
        path: 'email-activity',
        loadChildren: () => import('@app/features/email-activity/email-activity.routes'),
        title: 'common.email_activity',
      },
      {
        path: 'credit-transfers',
        loadChildren: () => import('@app/features/credit-transfers/credit-transfers.routes'),
        title: 'common.transfers',
      },
      {
        path: 'merchants',
        loadChildren: () => import('@app/features/merchants/merchants.routes'),
        title: 'common.partner_managment',
      },
      {
        path: 'customers',
        loadChildren: () => import('./features/clients/clients.routes'),
        title: 'common.customer',
      },
      {
        path: 'multipliers',
        loadChildren: () => import('./features/clients/clients.routes'),
        title: 'common.multiplier',
      },
      {
        path: 'admin-users',
        loadChildren: () => import('./features/users/users.routes'),
        title: 'common.users',
      },
      {
        path: 'push-notification',
        component: PushNotificationComponent,
        title: 'common.push_notification',
      },
      {
        path: 'lunch-tax-rules',
        component: LunchTaxRulesComponent,
        title: 'common.lunch_tax_rules',
      },
      {
        path: 'audit-logs',
        loadChildren: () => import('./features/audit-logs/audit-logs.routes'),
        title: 'common.audit_logs',
      },
      {
        path: 'vouchers',
        loadChildren: () => import('@app/features/voucher-details/voucher-details.routes'),
        title: 'common.voucher',
      },
      {
        path: 'card-designs',
        loadChildren: () => import('@app/features/card-designs/card-designs.routes'),
        title: 'common.card_designs',
      },
      {
        path: 'releasenotes',
        loadChildren: () => import('./features/releasenotes/releasenotes.routes'),
        title: 'common.releasenotes',
      },
      {
        path: 'payment-transactions',
        loadChildren: () => import('@app/features/payment-transaction-details/payment-transactions.routes'),
        title: 'common.payment_transactions',
      },
      {
        path: 'client-users',
        component: PublicUsersComponent,
        title: 'common.client-users-managment',
      },
    ],
  },
  {
    path: 'not-found',
    component: DisableLoadingComponent,
    children: [
      {
        path: '',
        component: NotFoundComponent,
        pathMatch: 'full',
        title: 'common.not_found',
      },
    ],
  },
  {
    path: '**',
    redirectTo: 'not-found',
    title: 'common.not_found',
  },
] as Routes;
