@if (vm$ | async; as vm) {
  <givve-content-toolbar
    [title]="'csv_upload.component.csv_upload_container.card-order.title' | translate"></givve-content-toolbar>

  <mat-card class="tw-mb-6">
    <mat-card-content class="tw-flex tw-gap-6">
      <div class="tw-w-2/3">
        @if (vm.statusType !== 'UPDATING') {
          <form
            [formGroup]="vm.csvUploadFormGroup"
            autocomplete="off">
            <givve-file-drop-area (fileAdded)="handleFileDropAreaFileAdded($event)">
              <div class="tw-text-[var(--csv-upload-dark-grey, var(--dark-grey))] tw-flex tw-items-center">
                <div class="tw-mr-3">
                  <svg-icon
                    key="upload"
                    fontSize="22px"></svg-icon>
                </div>
                <div class="tw-leading-normal">
                  @if (!vm.csvUploadFormGroup.getRawValue().file) {
                    <div class="tw-text-lg">
                      {{ 'csv_upload.component.csv_upload_content.place_csv_file' | translate }}
                    </div>
                    <div
                      class="tw-text-xs"
                      [innerHTML]="'csv_upload.component.csv_upload_content.or_select_folder' | translate"></div>
                  } @else {
                    <div class="tw-text-lg">{{ vm.csvUploadFormGroup.getRawValue().file.name }}</div>
                  }
                </div>
              </div>
            </givve-file-drop-area>

            @if (vm.csvUploadFormGroup.getRawValue().file) {
              @if (!vm.validationData.possibleCustomers.length || vm.validationData.possibleCustomers.length > 25) {
                <app-client-autocomplete-field
                  [clientType]="['customer']"
                  formControlName="customer" />
              } @else {
                <mat-form-field>
                  <mat-label>{{ 'common.customer' | translate }}</mat-label>
                  <mat-select
                    formControlName="customer"
                    data-cy="client-autocomplete-field-text-input">
                    @for (customer of vm.validationData.possibleCustomers; track customer) {
                      <mat-option [value]="customer"> {{ customer.name }} [{{ customer.number }}]</mat-option>
                    }
                  </mat-select>
                </mat-form-field>
              }
              @if (vm.csvUploadFormGroup.controls.customer.errors?.invalid_customer) {
                <mat-error>
                  {{ 'csv-upload.customer_needs_to_be_active' | translate }}
                </mat-error>
              }
              @if (vm.validationData.possibleTemplates.length >= 1) {
                <mat-form-field>
                  <mat-label>{{ 'common.product' | translate }}</mat-label>
                  <mat-select
                    formControlName="template"
                    data-cy="csv-upload-for-card-orders-card-order-type-select"
                    required>
                    @for (template of vm.validationData.possibleTemplates; track template) {
                      <mat-option [value]="template">
                        {{
                          template.label ? (template.label | translate) : ('common.' + template.category | translate)
                        }}</mat-option
                      >
                    }
                  </mat-select>
                  <mat-hint>{{ 'common.required' | translate }}</mat-hint>
                </mat-form-field>
              }
            }
          </form>
        } @else {
          <givve-content-loading loading />
        }

        @if (vm.statusType === 'ERROR') {
          <h3 class="tw-mb-0 tw-pt-4 tw-text-[var(--warn)]">{{ 'common.errors' | translate }}</h3>
          <mat-list>
            @for (item of vm.error?.['error']?.['errors']; track $index; let index = $index; let last = $last) {
              <mat-list-item class="tw-cursor-pointer hover:tw-bg-[#F1F1F1]">
                <div matListItemTitle>
                  <b>{{ item.source.pointer }}</b
                  >: {{ item.detail }}
                </div>
              </mat-list-item>
              @if (!last) {
                <mat-divider></mat-divider>
              }
            }
          </mat-list>
        }
      </div>

      <div class="tw-w-1/3">
        <h3 class="tw-my-2">{{ 'common.templates' | translate }}</h3>
        <div matListItemLine>{{ 'csv-upload.templates.info' | translate }}</div>
        <mat-list class="tw-pl-3">
          @for (item of vm.templates; track $index; let i = $index; let last = $last) {
            @if (i === 0 || vm.templates[i - 1].type !== item.type) {
              <span matListItemTitle>{{ 'common.' + item.type | translate }}</span>
              @if (item.description) {
                <div matListItemLine>{{ item.description | translate }}</div>
              }
            }

            <mat-list-item
              class="tw-cursor-pointer tw-pl-6 hover:tw-bg-[#F1F1F1]"
              (click)="downloadTemplate(item)">
              <div matListItemTitle>
                {{ item.label ? (item.label | translate) : ('common.' + item.category | translate) }}
              </div>
              <button
                (click)="openInfoForTemplateDialog(item, $event)"
                mat-icon-button
                matListItemMeta>
                <fa-icon icon="circle-info" />
              </button>
            </mat-list-item>
            @if (i + 1 < vm.templates.length && vm.templates[i + 1].type === item.type) {
              <mat-divider></mat-divider>
            }
          }
        </mat-list>
      </div>
    </mat-card-content>
  </mat-card>
}
