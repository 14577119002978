<form
  *ngrxLet="vm$ as vm"
  [formGroup]="form"
  (ngSubmit)="submit()">
  <givve-content-toolbar [title]="'common.lunch_tax_rules' | translate" />
  <mat-card>
    <mat-card-content>
      <div class="tw-gap tw-flex">
        <mat-form-field class="input-width">
          <mat-label>{{ 'tax-rules.date' | translate }}</mat-label>
          <input
            [matDatepicker]="picker"
            matInput
            formControlName="date"
            required />
          <mat-datepicker-toggle
            [for]="picker"
            matIconSuffix></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="tw-gap tw-flex">
        <mat-form-field class="input-width">
          <mat-label>{{ 'tax-rules.max_credit_amount' | translate }}</mat-label>
          <input
            matInput
            currencyMask
            required
            formControlName="max_credit_amount" />
        </mat-form-field>

        <mat-form-field class="input-width">
          <mat-label>{{ 'tax-rules.transaction_amount' | translate }}</mat-label>
          <input
            matInput
            currencyMask
            required
            formControlName="transaction_amount" />
        </mat-form-field>
      </div>
    </mat-card-content>
    <mat-card-actions>
      <button
        class="button-with-spinner tw-ml-auto"
        [disabled]="form.invalid || form.pristine || vm.statusType === 'LOADING'"
        color="primary"
        mat-flat-button
        type="submit">
        <givve-button-spinner [loading]="vm.statusType === 'LOADING'">
          {{ 'common.send' | translate }}
        </givve-button-spinner>
      </button>
    </mat-card-actions>
  </mat-card>

  @if (vm.statusType === 'DATA') {
    <h3>{{ 'common.result' | translate }}</h3>
    <table
      class="mat-elevation-z8"
      [dataSource]="[vm.lunchTaxRules]"
      mat-table>
      <ng-container matColumnDef="credit_amount">
        <th
          *matHeaderCellDef
          mat-header-cell>
          {{ 'tax-rules.credit_amount' | translate }}
        </th>
        <td
          *matCellDef="let element"
          mat-cell>
          <givve-amount [amount]="element.credit_amount" />
        </td>
      </ng-container>
      <ng-container matColumnDef="flat_taxed_amount">
        <th
          *matHeaderCellDef
          mat-header-cell>
          {{ 'tax-rules.flat_taxed_amount' | translate }}
        </th>
        <td
          *matCellDef="let element"
          mat-cell>
          <givve-amount [amount]="element.flat_taxed_amount" />
        </td>
      </ng-container>
      <ng-container matColumnDef="tax_free_amount">
        <th
          *matHeaderCellDef
          mat-header-cell>
          {{ 'tax-rules.tax_free_amount' | translate }}
        </th>
        <td
          *matCellDef="let element"
          mat-cell>
          <givve-amount [amount]="element.tax_free_amount" />
        </td>
      </ng-container>

      <tr
        *matHeaderRowDef="displayedColumnsResult"
        mat-header-row></tr>
      <tr
        *matRowDef="let row; columns: displayedColumnsResult"
        mat-row></tr>
    </table>

    <h3>{{ 'common.debugging' | translate }}</h3>
    <table
      class="mat-elevation-z8"
      [dataSource]="[vm.lunchTaxRules]"
      mat-table>
      <ng-container matColumnDef="tax_rule_class">
        <th
          *matHeaderCellDef
          mat-header-cell>
          tax_rule_class
        </th>
        <td
          *matCellDef="let element"
          mat-cell>
          {{ element.tax_rule_class }}
        </td>
      </ng-container>
      <ng-container matColumnDef="effective_max_credit_amount">
        <th
          *matHeaderCellDef
          mat-header-cell>
          effective_max_credit_amount
        </th>
        <td
          *matCellDef="let element"
          mat-cell>
          <givve-amount [amount]="element.effective_max_credit_amount" />
        </td>
      </ng-container>
      <ng-container matColumnDef="effective_flat_tax_limit">
        <th
          *matHeaderCellDef
          mat-header-cell>
          effective_flat_tax_limit
        </th>
        <td
          *matCellDef="let element"
          mat-cell>
          <givve-amount [amount]="element.effective_flat_tax_limit" />
        </td>
      </ng-container>
      <ng-container matColumnDef="effective_max_direct_tax_free_amount">
        <th
          *matHeaderCellDef
          mat-header-cell>
          effective_max_direct_tax_free_amount
        </th>
        <td
          *matCellDef="let element"
          mat-cell>
          <givve-amount [amount]="element.effective_max_direct_tax_free_amount" />
        </td>
      </ng-container>
      <ng-container matColumnDef="effective_max_transferred_tax_free_amount">
        <th
          *matHeaderCellDef
          mat-header-cell>
          effective_max_transferred_tax_free_amount
        </th>
        <td
          *matCellDef="let element"
          mat-cell>
          <givve-amount [amount]="element.effective_max_transferred_tax_free_amount" />
        </td>
      </ng-container>
      <tr
        *matHeaderRowDef="displayedColumnsDebugging"
        mat-header-row></tr>
      <tr
        *matRowDef="let row; columns: displayedColumnsDebugging"
        mat-row></tr>
    </table>
  }
</form>
